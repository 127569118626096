<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
    <path
      id="Icon_open-external-link"
      data-name="Icon open-external-link"
      d="M0,0V10H10V7.5H8.75V8.75H1.25V1.25H2.5V0ZM5,0,
      6.875,1.875,3.75,5,5,6.25,8.125,3.125,10,5V0Z"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'Open',
  props: {
    fillColor: {
      type: String,
      default: '#47040f',
    },
  },
};
</script>
