<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="10.714" height="15" viewBox="0 0 10.714 15">
    <path
      id="Icon_ionic-md-pin"
      data-name="Icon ionic-md-pin"
      d="M12.107,2.25A5.32,5.32,0,0,0,6.75,7.5c0,3.937,5.357,9.75,5.357,
      9.75s5.357-5.812,5.357-9.75A5.32,5.32,0,0,0,12.107,2.25Zm0,
      7.125A1.875,1.875,0,1,1,14.02,7.5,1.876,1.876,0,0,1,12.107,9.375Z"
      transform="translate(-6.75 -2.25)"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'Pin',
  props: {
    fillColor: {
      type: String,
      default: '#47040f',
    },
  },
};
</script>
