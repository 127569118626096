<template>
  <div class="marketing">
    <Banner
      ref="banner"
      title="MARKETING"
      subtitle="經銷據點"
      :img="bannerImg"
    />
    <PageNav
      :nav="locations"
      :initActiveIdx="initActiveIdx"
      @updateNowNav="updateNowNav"
      :class="{ fixed: isPageNavFixed }"
      :style="`top: ${headerHeight - 1 || 0}px;`"
    />
    <div class="page_wrapper">
      <div class="w1400">
        <div class="filter">
          <div class="left">
            <Select
              :customArrow="true"
              :format="inputFormat.citySelect"
              :selected.sync="formData.city"
            />
            <Select
              :customArrow="true"
              :format="inputFormat.typeSelect"
              :selected.sync="formData.tag"
            />
          </div>
          <div class="right">
            <Input
              :format="inputFormat.keywordInput"
              v-model.trim="formData.keyword"
              @keyup.enter.native="onSearch"
              @cleanValue="cleanKeyword"
              @composingStatus="updateComposingStatus"
              class="filter_keyword"
            />
            <button @click="onSearch">
              <Search strokeColor="#fff" />
            </button>
          </div>
        </div>

        <div class="filter_result">
          <div class="left">
            <ul class="noScrollBar">
              <li
                v-for="(marketing, idx) in marketings"
                :key="`marketing_${idx}`"
                @click="updateMarket(marketing, idx)"
              >
                <div
                  class="market_name large txt-black"
                  :class="[
                    idx === currentMarketIdx ? 'active' : '',
                  ]"
                >{{ marketing.name }}</div>
                <div class="p txt-dark_gray-1">
                  <Pin />
                  <span class="icon_info">{{ marketing.address }}</span>
                </div>
                <div class="p txt-dark_gray-1">
                  <Tel />
                  <span class="icon_info">{{ marketing.tel }}</span>
                </div>
                <div class="p txt-dark_gray-1">
                  <Fax />
                  <span class="icon_info">{{ marketing.fax }}</span>
                </div>
                <div class="tags">
                  <span
                    v-for="(tag, idx1) in marketing.tags"
                    :key="`tag_${idx1}`"
                    class="tag small-x"
                  >{{ tag }}</span>
                </div>
                <a
                  :href="`https://www.google.com.tw/maps/place/${marketing.address}`"
                  target="_blank"
                  class="google_map small txt-main"
                >
                  Google map <Open />
                </a>
              </li>
              <div v-show="!marketings.length">
                <p class="no_result_hint large txt-center txt-black">查無結果，請重新搜尋</p>
              </div>
            </ul>
          </div>
          <div class="right">
            <div
              v-if="map"
              v-html="map"
              class="map_wrapper"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/scss/marketing.scss';
import bannerImg from '@/assets/imgs/banner/banner_marketing.jpg';
import Banner from '@/components/banner/Index.vue';
import PageNav from '@/components/pageNav/Index.vue';
import Select from '@/components/form/Select.vue';
import Input from '@/components/form/Input.vue';
import Search from '@/components/icon/Search.vue';
import Pin from '@/components/icon/Pin.vue';
import Tel from '@/components/icon/Tel.vue';
import Fax from '@/components/icon/FaxSmall.vue';
import Open from '@/components/icon/Open.vue';

import publicMixin from '@/mixins/publicMixin';
import { isEqual, set } from '@/lib/lodash';
import requestApi from '@/lib/http';
import { formatLocations, formatCities, formatMarkegints } from '@/utils/formatResponse';

export default {
  name: 'Marketing',
  mixins: [publicMixin],
  components: {
    Banner,
    PageNav,
    Select,
    Input,
    Search,
    Pin,
    Tel,
    Fax,
    Open,
  },
  data() {
    return {
      initActiveIdx: 0,
      composingStatus: false,
      locations: [],
      marketings: [],
      currentMarketIdx: 0,
      bannerImg,
      isPageNavFixed: false,
      formData: {
        city: null,
        tag: null,
        keyword: '',
      },
      headerNowNav: '',
      nowNav: '',
      map: '',
      allCities: [],
      cityOptions: [],
      tagOptions: [],
    };
  },
  computed: {
    headerHeight() {
      return this.$store.state.site.headerHeight;
    },
    inputFormat() {
      return {
        citySelect: {
          placeholder: '請選擇縣市',
          options: this.cityOptions,
        },
        typeSelect: {
          placeholder: '請選擇經銷類型',
          options: this.tagOptions,
        },
        keywordInput: {
          type: 'text',
          placeholder: '搜尋經銷商名稱或地址',
        },
      };
    },
  },
  methods: {
    setInitPageNavIdx() {
      this.initActiveIdx = this.locations
        .findIndex((location) => location.name === this.nowNav);
    },
    updateNowNav(val, name) {
      this.nowNav = name;
      this.currentMarketIdx = 0;

      this.scrollToTop(); // from publicMixin.js
    },
    cleanKeyword() {
      this.formData.keyword = '';
      this.onSearch();
    },
    updateMarket(data, idx) {
      this.currentMarketIdx = idx;
      this.map = data.map;
    },
    formatCityOptions(data) {
      const storeCity = {};
      data.forEach((item) => {
        storeCity[item.city.name] = item.city.name;
      });

      const cityOptions = [];
      Object.keys(storeCity).forEach((key) => {
        this.allCities.forEach((city) => {
          if (city.name === key) cityOptions.push(city);
        });
      });

      this.cityOptions = cityOptions.map((option) => ({
        label: option.name,
        label_en: option.name_en,
        value: option.name,
      }));
    },
    formatTagOptions(data) {
      const storeTag = {};
      data.forEach((item) => {
        if (item.tags?.length) {
          item.tags.forEach((tag) => {
            storeTag[tag] = tag;
          });
        }
      });

      const tagOptions = [];
      Object.keys(storeTag).forEach((key) => {
        tagOptions.push({ label: key, value: key });
      });

      this.tagOptions = tagOptions;
    },
    async getLocations() {
      const { entries: data } = await requestApi('common.getParts');
      this.locations = data.map(formatLocations);
    },
    async getCities() {
      const { entries: data } = await requestApi('common.getCities');
      this.allCities = data.map(formatCities);
    },
    async getData(payload, resetOptions = false) {
      const { entries: data } = await requestApi('common.getStores', {
        filter: payload,
      });

      this.marketings = data.map(formatMarkegints);

      if (resetOptions) {
        this.formatCityOptions(this.marketings);
        this.formatTagOptions(this.marketings);
      }
    },
    onSearch() {
      if (this.composingStatus) {
        this.composingStatus = false;
        return;
      }
      const query = {
        part: this.nowNav,
      };

      if (this.formData.city) {
        query.city = this.formData.city;
      }
      if (this.formData.tag) {
        query.tag = this.formData.tag;
      }
      if (this.formData.keyword) {
        set(query, 'title.$regex', this.formData.keyword);
      }

      this.getData(query);
    },
    scrollHandler() {
      if (window.scrollY > this.bannerHeight) {
        this.isPageNavFixed = true;
      } else {
        this.isPageNavFixed = false;
      }
    },
    updateComposingStatus(status) {
      this.composingStatus = status;
    },
  },
  created() {
    window.addEventListener('scroll', this.scrollHandler);
  },
  async mounted() {
    await this.getCities();
    /**
     * Get Banner height for scroll method
     */
    this.bannerHeight = this.$refs.banner.$el.clientHeight;

    await this.getLocations();
    this.nowNav = this.headerNowNav || this.locations?.[0]?.name;

    this.setInitPageNavIdx();

    this.getData({
      part: this.nowNav,
    }, true);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          const { series_main: nowNav } = newVal.query;
          if (nowNav) {
            this.headerNowNav = nowNav;
            this.nowNav = nowNav;
            this.setInitPageNavIdx();
          }
        }
      },
    },

    /** 切換區域頁籤時，重置篩選表單，重call api */
    nowNav: {
      immediate: true,
      handler(val) {
        this.formData.city = null;
        this.formData.tag = null;
        this.formData.keyword = '';

        this.getData({
          part: val,
        }, true);
      },
    },
    'formData.city': {
      handler() {
        this.formData.tag = null;
        this.formData.keyword = '';

        const query = {
          part: this.nowNav,
        };

        if (this.formData.city) query.city = this.formData.city;

        this.getData(query);
      },
    },
    'formData.tag': {
      handler() {
        this.formData.keyword = '';

        const query = {
          part: this.nowNav,
        };

        if (this.formData.city) query.city = this.formData.city;
        if (this.formData.tag) query.tag = this.formData.tag;

        this.getData(query);
      },
    },
    /** 預設顯示列表第一筆資料的google map */
    marketings: {
      deep: true,
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal) && newVal.length) {
          this.map = newVal?.[0]?.map || '';
        }
      },
    },
  },
};
</script>
