<template>
  <div class="page_nav iosHideScrollBar">
    <ul class="overflow_box noScrollBar">
      <li v-for="(item, idx) in nav" :key="`nav_${idx}`">
        <button
          class="page_nav_btn h5 txt-medium_gray"
          :class="{ active: activeIdx === idx }"
          @click="clickHandler(idx, item.name)"
        >{{ locationName(item) }}</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PageNav',
  props: {
    nav: {
      type: Array,
      default() {
        return [];
      },
    },
    initActiveIdx: {
      type: Number,
      default: 0,
    },
    scrollActiveIdx: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activeIdx: 0,
    };
  },
  methods: {
    locationName(location) {
      return this.$store.state.site.lang === 'TW' ? location.name : location.name_en;
    },
    updateIdx(idx) {
      this.activeIdx = idx;
    },
    clickHandler(idx, name) {
      this.updateIdx(idx);
      this.$emit('updateNowNav', idx, name);
    },
  },
  watch: {
    initActiveIdx: {
      immediate: true,
      handler() {
        this.activeIdx = this.initActiveIdx;
      },
    },
    scrollActiveIdx: {
      handler(val) {
        this.activeIdx = val;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_variable.scss';
.page_nav {
  // &.iosHideScrollBar {
  //   height: 78px;
  //   overflow: hidden;
  //   > ul {
  //     height: 85px;
  //   }
  // }
  ul {
    text-align: center;
    li {
      margin: 3.5rem 1rem 0;
      display: inline-block;
    }
  }
  &_btn {
    padding-bottom: 1.1rem;
    border-bottom: 3px solid transparent;
    &.active {
      color: $color-main;
      border-color: $color-red;
    }
  }
  @media screen and (max-width: 1440px) {
    ul {
      li {
        margin-top: 2.5rem;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    ul {
      li {
        margin-top: 2rem;
      }
    }
  }
  @media screen and (max-width: 768px) {
    &.iosHideScrollBar {
      height: 63px;
      > ul {
        height: 75px;
      }
    }
    &_btn {
      padding-bottom: 0.7rem;
    }
    &.fixed {
      background-color: $color-gray;
      position: sticky;
      width: 100%;
      background-color: $color-gray;
      z-index: 1;
      ul {
        li {
          margin: 1.2rem 1rem 0;
        }
      }
      .page_nav_btn {
        padding-bottom: 1.05rem;
      }
    }
    ul {
      li {
        margin-top: 1.5rem;
      }
    }
  }
}
</style>
