<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19.299" height="18.983" viewBox="0 0 19.299 18.983">
    <g id="Group_435" data-name="Group 435" transform="translate(0 -1.274)">
      <g
        id="Ellipse_9" data-name="Ellipse 9" transform="translate(0 1.274)"
        fill="none" :stroke="strokeColor" stroke-width="1.5"
      >
        <circle cx="8" cy="8" r="8" stroke="none"/>
        <circle cx="8" cy="8" r="7.25" fill="none"/>
      </g>
      <path
        id="Path_27" data-name="Path 27" d="M-1055.639-1239.955l5.408,5.408"
        transform="translate(1069 1254.274)" fill="none" :stroke="strokeColor" stroke-width="1.5"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Search',
  props: {
    strokeColor: {
      type: String,
      default: '#47040f',
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
