<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16.115" height="16.114" viewBox="0 0 16.115 16.114">
    <path
      id="Icon_ionic-md-close-circle"
      data-name="Icon ionic-md-close-circle"
      d="M11.432,3.375a8.057,8.057,0,1,0,8.057,8.057A8.029,8.029,0,0,0,11.432,
      3.375Zm4.029,10.958-1.128,1.128-2.9-2.9-2.9,2.9L7.4,14.333l2.9-2.9-2.9-2.9L8.532,
      7.4l2.9,2.9,2.9-2.9,1.128,1.128-2.9,2.9Z"
      transform="translate(-3.375 -3.375)" :fill="fillColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'Clean',
  props: {
    fillColor: {
      type: String,
      default: '#c4c4c4',
    },
  },
  components: {

  },
  data() {
    return {

    };
  },
  computed: {

  },
  methods: {

  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang="scss" scoped>

</style>
