<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
    <path
      id="Icon_awesome-phone-alt" data-name="Icon awesome-phone-alt"
      d="M14.572,10.6,11.291,9.194a.7.7,0,0,0-.82.2L9.017,11.171A10.859,
      10.859,0,0,1,3.826,5.98L5.6,4.527a.7.7,0,0,0,.2-.82L4.4.425A.708.708,0,0,0,
      3.592.018L.545.721A.7.7,0,0,0,0,1.407,13.592,13.592,0,0,0,13.594,
      15a.7.7,0,0,0,.686-.545l.7-3.047a.712.712,0,0,0-.41-.809Z"
      transform="translate(0 0)"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'Tel',
  props: {
    fillColor: {
      type: String,
      default: '#47040f',
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
