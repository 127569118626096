<template>
  <div class="form_input" :class="{ disabled: format.disabled }">
    <label :for="format.labelValue" class="label_box small">

      <!-- Title -->
      <div class="label_name" v-if="format.labelName">
        {{ format.labelName }}
        <span v-if="format.required" class="txt-red">*</span>
      </div>

      <!-- Content -->
      <div class="label_content">

        <div class="input_readonly small" v-if="readonly">
          {{ readonly }}
        </div>

        <input
          v-else
          :id="format.labelValue"
          :class="{ 'txt-center': text === 'center' }"
          :type="toggleType"
          :value="value"
          :error="hasError"
          :maxlength="maxlength"
          :placeholder="format.placeholder"
          :readonly="format.disabled"
          @blur="test()"
          @input="
            $emit('input', $event.target.value);
            onInput($event);
          "
        >

        <!-- Error Message -->
        <div class="err_msg small-x txt-red">
          <slot name="modalBtn"></slot>
          {{ errorMsg }}
        </div>

        <button
          @click="cleanValue"
          class="clean_icon"
        >
          <Clean />
        </button>
      </div>
    </label>
  </div>
</template>

<script>
import Clean from '@/components/icon/Clean.vue';

export default {
  name: 'FormInput',
  props: {
    format: {
      type: Object,
      default: undefined,
      required: true,
    },
    rules: {
      type: Array,
      default: undefined,
    },
    maxlength: {
      type: Number,
      default: undefined,
    },
    value: {
      type: String,
      default: undefined,
    },
    text: {
      type: String,
      default: undefined,
    },
    readonly: {
      type: String,
      default: '',
    },
  },
  components: {
    Clean,
  },
  data() {
    return {
      errorMsg: '',
      toggleType: this.format.type,
    };
  },
  computed: {
    hasRules() {
      return this.rules && this.rules.length > 0;
    },
    hasError() {
      if (this.hasRules && this.errorMsg !== '') return true;
      return false;
    },
  },
  methods: {
    typeToggle() {
      if (this.toggleType === 'password') {
        this.toggleType = 'text';
      } else if (this.toggleType === 'text') {
        this.toggleType = 'password';
      }
    },
    validate(val = this.value) {
      if (this.hasRules) {
        const errors = [];
        this.rules.forEach((rule) => {
          if (typeof rule === 'function') {
            if (rule(val) !== true) {
              errors.push(rule(val));
            }
          } else if (typeof rule === 'object') {
            if (val && !val.match(rule[0])) {
              errors.push(rule[1]);
            }
          }
        });
        if (errors.length) {
          return errors[0];
        }
      }
      return '';
    },
    test() {
      this.errorMsg = this.validate(this.value) ? this.validate(this.value) : '';
    },
    cleanValue() {
      this.$emit('cleanValue');
    },
    onInput(e) {
      // console.log('input', e.isComposing);
      this.$emit('composingStatus', e.isComposing);
    },
  },
  watch: {
    value() {
      this.test();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_variable.scss';
.form_input {
  max-width: 300px;
  input {
    padding: 0 10px;
    height: 44px;
    background-color: #fff;
    font-size: 1rem;
  }
  .label_content {
    position: relative;
  }
  .clean_icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-100%, -50%);
    svg {
      width: 100%;
      display: block;
    }
  }
}
</style>
